<template>
  <div id="data-explorer-preview" class="card-body column">
    <TableWrapper :meta="meta" @reload-data="loadData">
      <template #filter>
        <v-select
          v-model="selectedFilter"
          class="form-control"
          :items="filters"
          :placeholder="$t('dataExplorer.filter')"
          :menu-props="{ offsetY: true, closeOnClick: true }"
          @change="loadData"
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0" class="font-size-lg ml-3">
              {{ $t("dataExplorer." + item) }}
            </span>
          </template>
          <template #item="{ item, index }">{{
            $t("dataExplorer." + item)
          }}</template>
        </v-select>
      </template>
      <div class="position-relative">
        <b-table
          id="data-explorer-preview-table"
          :busy="isBusy"
          responsive
          :items="data"
          :fields="fields"
          class="dataTable table table-head-custom overflow-x-auto"
          show-empty
          :empty-text="$t('table.noRecords')"
        >
          <template #cell()="data">
            <span v-if="JSON.stringify(data.value).length > maxDataLength">
              {{ formatCellData(data.value) }}
              <span
                class="label label-inline label-light-secondary px-1 cursor-pointer"
                @click="showFullValue(data.field.key, data.value)"
                >[...]
              </span>
            </span>
            <span v-else>
              {{ data.value }}
            </span>
          </template>
          <template #cell(__table_actions)="data">
            <button
              v-b-tooltip.top.noninteractive="$t('dataStructures.show')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="showFullValue(data.item._primary, data.item, false)"
            >
              <i class="fal fa-magnifying-glass" />
            </button>
            <button
              v-b-tooltip.top.noninteractive="$t('dataStructures.edit')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="showFullValue(data.item._primary, data.item, true)"
            >
              <i class="fal fa-pencil" />
            </button>
            <button
              v-b-tooltip.top.noninteractive="$t('dataStructures.deleteTitle')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="deleteEntry(data.item)"
            >
              <i class="fal fa-trash" />
            </button>
            <button
              v-b-tooltip.top.noninteractive="
                data.item._dirty
                  ? $t('dataExplorer.setCleanDataStructureEntry')
                  : $t('dataExplorer.setDirtyDataStructureEntry')
              "
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="toggleDirty(data.item)"
            >
              <i v-if="data.item._dirty" class="fa-regular fa-toggle-off" />
              <i v-else class="fa-regular fa-toggle-on" />
            </button>
          </template>
          <template #table-busy>
            <div class="text-center text-dark my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>{{ $t("general.loading") }}...</strong>
            </div>
          </template>
        </b-table>
      </div>
    </TableWrapper>
    <b-modal
      id="data-explorer-modal"
      v-model="modal.show"
      size="xl"
      :title="modal.title.toString()"
      @hidden="closeModal"
    >
      <div v-if="!modal.editMode">
        <vue-json-pretty
          v-if="typeof modal.data === 'object'"
          highlight-mouseover-node
          :data="modal.data"
        />
        <span v-else>{{ modal.data }}</span>
      </div>
      <div v-else>
        <prism-editor
          v-model="modal.dataString"
          class="jsonEditor"
          :tab-size="6"
          style="min-height: 45vh !important; max-width: 100%"
          :highlight="highlighterJson"
          line-numbers
        />
        <div
          v-if="!validJson"
          class="mt-7 alert alert-danger"
          v-html="$t('processManager.invalidJson')"
        ></div>
      </div>

      <template #modal-footer>
        <button class="btn btn-secondary" @click="copyValue">
          {{ $t("general.copy") }}
        </button>
        <button
          v-if="modal.editMode && validJson"
          class="btn btn-success"
          @click="updateData"
        >
          {{ $t("general.save") }}
        </button>
        <button v-if="modal.editMode && !validJson" class="btn btn-secondary">
          {{ $t("general.save") }}
        </button>
        <button class="btn btn-primary" @click="closeModal">
          {{ $t("general.close") }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { DataStructures } from "@/components/Admins/Settings/DataStructures/dataStructures";
import QueryEditor from "@/components/Projects/OldDataStore/queryEditor";
import TableWrapper from "@/components/Tools/TableWrapper";
import { copyToClipboard } from "@/components/Tools/helperFunctions";
import $ from "jquery";
import { mapGetters } from "vuex";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-markup.js";
import "prismjs/components/prism-markup-templating.js";
import "prismjs/components/prism-json";

export default {
  components: { TableWrapper, PrismEditor },
  props: {
    dataStructure: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      fields: [],
      filters: ["dirty", "clean", "noFilter"],
      selectedFilter: null,
      data: [],
      modal: {
        show: false,
        data: undefined,
        dataString: "",
        title: "",
        editMode: false
      },
      jsonPrettyData: {},
      // Meta info
      meta: {} ?? { from: 1, to: 1, total: 1 },
      isBusy: false,
      maxDataLength: 40
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"]),
    validJson() {
      if (this.modal.dataString.length > 1) {
        try {
          JSON.parse(this.modal.dataString);
        } catch (e) {
          return false;
        }
      }
      return true;
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.fields.push({
          key: "_dirty",
          label: this.data._dirty,
          sortable: true,
          systemField: true
        });
        this.fields.push({
          key: "created_at",
          label: this.data.created_at,
          sortable: true,
          systemField: true
        });
        this.fields.push({
          key: "updated_at",
          label: this.data.updated_at,
          sortable: true,
          systemField: true
        });
        this.fields.push({
          key: "__table_actions",
          label: ""
        });
      }
    }
  },
  async mounted() {
    await this.getFields();
    this.loadData();
  },
  methods: {
    highlighterJson(code) {
      return highlight(code, languages.json);
    },
    async getFields() {
      let fields = [];
      let sourceFields = this.dataStructure.fields;
      sourceFields.forEach(field => {
        fields.push({
          key: field.full_name,
          label: field.label,
          sortable: true
        });
      });

      this.fields = fields;
    },
    loadData() {
      this.isBusy = true;
      this.entries = [];
      let params = this.requestParams();
      if (this.selectedFilter && this.selectedFilter !== "noFilter") {
        Object.assign(params, this.getAdditionalRequestParams());
      }
      this.loadDataStructureData(params);
    },
    loadDataStructureData(params) {
      const dataStructures = new DataStructures();
      let cancelToken = this.cancelToken();
      dataStructures
        .getData(this.dataStructure.id, params, cancelToken)
        .then(response => {
          this.data = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
          this.$nextTick().then(() => this.updateActionsHeight());
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    loadDataSetData(params) {
      const queryData = {
        name: this.dataSet.name,
        query: this.dataSet.query
      };
      QueryEditor.runQuery(queryData, params)
        .then(response => {
          this.data = response.data.data.items;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.isBusy = false;
        });
    },
    getAdditionalRequestParams() {
      let params = {};
      params["_dirty"] = this.selectedFilter === "dirty";
      return params;
    },
    formatCellData(value) {
      let asString = JSON.stringify(value);
      if (asString.length <= this.maxDataLength) {
        return asString;
      }
      return asString.substring(0, this.maxDataLength);
    },
    showFullValue(field, value, editMode = false) {
      this.modal = {
        title: field,
        data: value,
        dataString: JSON.stringify(value, null, "\t"),
        show: true,
        editMode: editMode
      };
    },
    deleteEntry(item) {
      let id = item._id;
      let name = item._primary;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("dataStructures.deleteEntryTitle"),
          text: this.$t("dataStructures.deleteEntryText", { name: name }),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel"),
          confirmButtonText: this.$t("general.delete"),
          confirmButtonColor: "#F64E60"
        })
        .then(result => {
          if (!result.isConfirmed) return;
          this.deleteEntryAction(id);
        });
    },
    deleteEntryAction(id) {
      this.isBusy = true;
      const dataStructures = new DataStructures();

      dataStructures
        .deleteDataStructureData(this.dataStructure.id, id)
        .then(() => {
          this.loadData();
          this.closeModal();
        })
        .catch(error => {
          this.$error(error);
          console.log(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    closeModal() {
      this.modal = {
        show: false,
        data: undefined,
        title: "",
        editMode: false
      };
    },
    copyValue() {
      let dataString = JSON.stringify(this.modal.data);
      copyToClipboard(dataString);
    },
    updateActionsHeight() {
      let dataExplorerTableLastChild = $(
        "#data-explorer-preview-table td:last-child, #data-explorer-preview-table th:last-child"
      );
      dataExplorerTableLastChild.each(function() {
        $(this).height(
          $(this)
            .parent()
            .height() - 26
        );
      });
      dataExplorerTableLastChild.prev().css("min-width", "275px");
    },
    updateData() {
      const dataStructures = new DataStructures();
      let cancelToken = this.cancelToken();

      const data = JSON.parse(this.modal.dataString);
      const entryId = this.modal.data._id;

      delete data._id;
      //delete data[this.dataStructure.primary];

      dataStructures
        .updateDataStructureData(
          this.dataStructure.id,
          entryId,
          data,
          cancelToken
        )
        .then(() => {
          this.loadData();
          this.closeModal();
        })
        .catch(error => {
          this.$error(error);
          console.log(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    toggleDirty(data) {
      this.isBusy = true;
      const dataStructures = new DataStructures();

      if (data._dirty) {
        dataStructures
          .setEntryClean(this.dataStructure.id, data._id)
          .then(() => {
            this.loadData();
          })
          .catch(error => {
            this.$error(error);
            console.log(error);
            this.isBusy = this.axios.isCancel(error);
          });
      } else {
        dataStructures
          .setEntryDirty(this.dataStructure.id, data._id)
          .then(() => {
            this.loadData();
          })
          .catch(error => {
            this.$error(error);
            console.log(error);
            this.isBusy = this.axios.isCancel(error);
          });
      }
    }
  }
};
</script>

<style>
#data-explorer-preview #data-explorer-preview-table th:nth-last-child(-1) {
  min-width: 400px;
}
#data-explorer-preview #data-explorer-preview-table td:nth-last-child(-1) {
  min-width: 400px;
}
</style>

<style lang="scss">
#data-explorer-preview {
  #data-explorer-preview-table {
    margin-right: 70px;
    th {
      text-transform: none;
      min-width: 100px;

      &:last-child {
        position: absolute;
        top: auto;
        right: 0;
        background-color: #fff;
        border-left: 2px solid #ebedf3;
        min-width: 175px;
        width: 175px;
        transform: translateY(-1px);

        &::before {
          position: absolute;
          display: block;
          content: "";
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.9) 50%
          );
          height: calc(100% - 2px);
          top: 1px;
          left: -35px;
          width: 34px;
        }
      }
    }

    td {
      max-width: 250px;

      &:last-child {
        position: absolute;
        top: auto;
        right: 0;
        background-color: #fff;
        border-left: 2px solid #ebedf3;
        border-top: 1px solid #ebedf3;
        min-width: 175px;
        width: 175px;
        transform: translateY(-1px);
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
          position: absolute;
          display: block;
          content: "";
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.9) 50%
          );
          height: calc(100% - 2px);
          top: 1px;
          left: -35px;
          width: 34px;
        }
      }
    }
  }
}

#data-explorer-modal {
  height: 100vh;
  overflow: hidden;

  .modal-dialog {
    height: calc(100% - 45px);
    overflow: hidden;

    .modal-content {
      height: 100%;
      overflow: hidden;

      .modal-body {
        overflow-y: auto;
      }
    }
  }
}

.jsonEditor {
  background: #2d2d2d;
  color: #ccc;
  min-height: 350px;
  padding: 10px 0;
  max-height: 300px;
  pre {
    color: #ccc;
  }
}
.prism-editor__container {
  height: 100%;
  min-height: 300vh !important;
}
.prism-editor__textarea:focus {
  outline: none;
}
</style>
