<template>
  <div class="data-explorer card card-custom grid-stack-item-content">
    <div class="card-header">
      <div class="card-title">
        {{ $t("dataExplorer.dataExplorerResources") }}
      </div>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadData">
        <b-table
          responsive
          :items="entries"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(label)="data">
            <span v-if="data.item.label">
              {{ data.item.label }}
            </span>
            <span v-else class="text-muted">
              {{ data.item.classname }}
            </span>
          </template>
          <template #cell(source)="data">
            <div v-if="data.item.owner_type === ''">
              <i
                v-b-tooltip.top.noninteractive="
                  $t('dataExplorer.dataStructure')
                "
                class="fal fa-list-tree fa-xl"
              />
            </div>
            <div v-else-if="data.item.owner_type.includes('Connector')">
              <i
                v-b-tooltip.top.noninteractive="
                  $t('dataExplorer.dataStructureFromRequest')
                "
                class="fal fa-code-pull-request fa-xl"
              />
            </div>
            <div v-else-if="data.item.owner_type.includes('DataPipeline')">
              <i
                v-b-tooltip.top.noninteractive="$t('dataExplorer.dataSet')"
                class="fal fa-filter-list fa-xl"
              />
            </div>
          </template>
          <template #cell(entries_count)="data">
            <div v-if="data.item.owner_type.includes('DataPipeline')">
              <span class="text-muted">~ {{ data.item.data_entry_count }}</span>
            </div>
            <div v-else>
              <span class="text-muted pl-3">{{
                data.item.data_entry_count
              }}</span>
            </div>
          </template>
          <template #cell(dirty_entries_count)="data">
            <div v-if="data.item.owner_type.includes('DataPipeline')">
              <span class="text-muted">n/a</span>
            </div>
            <div v-else>
              <span class="text-muted"
                >{{ data.item.dirty_data_entry_count }} /
                {{ data.item.data_entry_count }}
              </span>
            </div>
          </template>
          <template #cell(last_updated)="data">
            <div v-if="data.item.last_updated_at">
              <span class="text-muted">{{ data.item.last_updated_at }} </span>
            </div>
            <div v-else-if="data.item.updated_at">
              <span class="text-muted">{{ data.item.updated_at }} </span>
            </div>
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              ref="helpDD"
              size="sm"
              variant="link"
              toggle-class="btn btn-icon btn-light btn-sm mr-1 text-decoration-none"
              no-caret
              right
              no-flip
            >
              <template #button-content>
                <i class="fal fa-ellipsis-v"></i>
              </template>
              <b-dropdown-text tag="div" class="min-w-md-200px p-0">
                <ul
                  v-if="data.item.owner_type === '' || data.item.owner_type.includes('Connector')"
                  class="navi navi-hover p-0 py-2"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="navi-item"
                  >
                    <a class="navi-link" @click="openDetails(data.item)">
                      <span class="navi-text">
                        {{ $t("dataExplorer.openDetails") }}
                      </span>
                    </a>
                  </li>
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="navi-item"
                  >
                    <a class="navi-link" @click="openEditor(data.item)">
                      <span class="navi-text">
                        {{ $t("dataExplorer.editDataStructure") }}
                      </span>
                    </a>
                  </li>
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="navi-item"
                  >
                    <a
                      class="navi-link"
                      @click="truncateDataStructure(data.item)"
                    >
                      <span class="navi-text">
                        {{ $t("dataExplorer.truncateDataStructure") }}
                      </span>
                    </a>
                  </li>
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="navi-item"
                  >
                    <a class="navi-link" @click="cleanDataStructure(data.item)">
                      <span class="navi-text">
                        {{ $t("dataExplorer.setCleanDataStructure") }}
                      </span>
                    </a>
                  </li>
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="navi-item"
                  >
                    <a class="navi-link" @click="dirtyDataStructure(data.item)">
                      <span class="navi-text">
                        {{ $t("dataExplorer.setDirtyDataStructure") }}
                      </span>
                    </a>
                  </li>
                </ul>
                <ul
                  v-if="data.item.owner_type.includes('DataPipeline')"
                  class="navi navi-hover p-0 py-2"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="navi-item"
                  >
                    <a class="navi-link" @click="openDetails(data.item)">
                      <span class="navi-text">
                        {{ $t("dataExplorer.openDetails") }}
                      </span>
                    </a>
                  </li>
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="navi-item"
                  >
                    <a class="navi-link" @click="openEditor(data.item)">
                      <span class="navi-text">
                        {{ $t("dataExplorer.editDataSet") }}
                      </span>
                    </a>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { DataStructures } from "@/components/Admins/Settings/DataStructures/dataStructures";
import QueryEditor from "@/components/Projects/OldDataStore/queryEditor";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

const DataStructureService = new DataStructures();
export default {
  components: { TableWrapper },
  props: {},
  data() {
    return {
      entries: [],
      fields: [
        {
          key: "label",
          label: this.$t("table.label"),
          sortable: true
        },
        {
          // TODO: YEDI-741 - Wait for core development and adapt
          key: "source",
          label: this.$t("table.source"),
          sortable: true
        },
        {
          key: "entries_count",
          label: this.$t("dataExplorer.entriesCount"),
          sortable: false
        },
        {
          key: "dirty_entries_count",
          label: this.$t("dataExplorer.dirtyEntriesCount"),
          sortable: false
        },
        {
          key: "last_updated",
          label: this.$t("dataExplorer.lastUpdated"),
          sortable: false
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          class: "text-right"
        }
      ],
      // Meta info
      meta: {},
      isBusy: false
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"]),
    getOrderBy: function () {
      return {
        "orderBy[label]": "asc",
        "orderBy[classname]": "asc"
      };
    }
  },
  mounted() {
    this.setBreadcrumbs();
    this.loadData();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.projectDataExplorer"),
          route: "/project/data-store"
        }
      ]);
    },
    setType(type) {
      if (type === this.type) {
        return;
      }
      if (type !== this.$route.query.type) {
        this.$router.replace({ query: { type: type } });
      }
      this.type = type;
      this.page = 1;
      this.loadData();
    },
    loadData() {
      this.isBusy = true;
      this.entries = [];
      let params = this.requestParams();
      this.loadDataStructures(params);
    },
    loadDataStructures(params) {
      let paramsComplete = Object.assign({}, params, this.getOrderBy);
      let cancelToken = this.cancelToken();
      const dataStructures = new DataStructures();
      dataStructures
        .getAll(paramsComplete, cancelToken)
        .then(response => {
          this.entries = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    loadDataSets(params) {
      QueryEditor.loadAll(params)
        .then(response => {
          this.entries = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.isBusy = false;
        });
    },
    openDetails(entry) {
      if (entry.owner_type.includes("DataPipeline")) {
        this.$router.push({
          name: "projectDataExplorerDetails",
          params: { id: entry.owner_id }
        });
      }
      this.$router.push({
        name: "projectDataExplorerDetails",
        params: { id: entry.id }
      });
    },
    openEditor(entry) {
      if (entry.owner_type.includes("DataPipeline")) {
        this.$router.push({
          name: "projectDataSetsEditor",
          params: { id: entry.owner_id }
        });
      } else {
        this.$router.push({
          name: "projectDataStructuresEditor",
          params: { id: entry.id }
        });
      }
    },
    truncateDataStructure(entry) {
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("dataExplorer.truncateTitle"),
          text: this.$t("dataExplorer.truncateText", {
            name: entry.currentLabel
          }),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("dataExplorer.cancel"),
          confirmButtonText: this.$t("dataExplorer.confirm"),
          confirmButtonColor: "#F64E60"
        })
        .then(result => {
          if (!result.isConfirmed) return;
          this.truncateDataStructureAction(entry);
        });
    },
    truncateDataStructureAction(entry) {
      this.isBusy = true;
      DataStructureService.truncate(entry.id)
        .then(() => {
          this.loadData();
          this.$toast.fire({
            icon: "success",
            title: this.$t("dataSets.deletedSuccess")
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.isBusy = false;
        });
    },
    cleanDataStructure(entry) {
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("dataExplorer.setCleanTitle"),
          text: this.$t("dataExplorer.setCleanText", {
            name: entry.currentLabel
          }),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("dataExplorer.cancel"),
          confirmButtonText: this.$t("dataExplorer.confirm"),
          confirmButtonColor: "#F64E60"
        })
        .then(result => {
          if (!result.isConfirmed) return;
          this.cleanDataStructureAction(entry);
        });
    },
    cleanDataStructureAction(entry) {
      this.isBusy = true;
      DataStructureService.setClean(entry.id)
        .then(() => {
          this.loadData();
          this.$toast.fire({
            icon: "success",
            title: this.$t("dataExplorer.setCleanSuccess")
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.isBusy = false;
        });
    },
    dirtyDataStructure(entry) {
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("dataExplorer.setDirtyTitle"),
          text: this.$t("dataExplorer.setDirtyText", {
            name: entry.currentLabel
          }),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("dataExplorer.cancel"),
          confirmButtonText: this.$t("dataExplorer.confirm"),
          confirmButtonColor: "#F64E60"
        })
        .then(result => {
          if (!result.isConfirmed) return;
          this.dirtyDataStructureAction(entry);
        });
    },
    dirtyDataStructureAction(entry) {
      this.isBusy = true;
      DataStructureService.setDirty(entry.id)
        .then(() => {
          this.loadData();
          this.$toast.fire({
            icon: "success",
            title: this.$t("dataExplorer.setDirtySuccess")
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.isBusy = false;
        });
    }
  }
};
</script>

<style lang="scss">
@media (max-width: 767px) {
  .table-responsive .dropdown-menu {
    position: static !important;
  }
}
@media (min-width: 768px) {
  .table-responsive {
    overflow: inherit;
  }
}

.btn-group {
  .btn {
    border-radius: 5px;
  }
}

.card.data-explorer {
  .btn {
    &.btn-light {
      color: #7e8299;
    }
  }
}

.b-dropdown-text {
  padding: 0 !important;
}
</style>
