<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        {{ title }}
      </div>
      <div class="card-toolbar">
        <button
          v-b-tooltip.top.noninteractive="$t('dataExplorer.editDataStructure')"
          class="btn btn-icon btn-light btn-sm mr-1"
          @click="openEditor"
        >
          <i class="fal fa-paint-brush" />
        </button>
        <button class="btn btn-secondary" @click="goBack">
          {{ $t("general.backToOverview") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div v-if="ready && dataStructure !== []" class="card-body">
      <Preview :data-structure="dataStructure" />
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { DataStructures } from "@/components/Admins/Settings/DataStructures/dataStructures";
import QueryEditor from "@/components/Projects/OldDataStore/queryEditor";
import Preview from "@/components/Projects/DataExplorer/Details/Preview";

export default {
  components: { Preview },
  props: {},
  data() {
    return {
      id: this.$route.params.id,
      dataStructure: undefined,
      tabIndex: 0,
      ready: false,
      isBusy: false
    };
  },
  computed: {
    title: function () {
      return this.$t("dataExplorer.dataStructure") + " - " + this.sourceLabel;
    },
    sourceLabel: function () {
      if (!this.dataStructure) {
        return "";
      }
      return this.dataStructure.label
        ? this.dataStructure.label
        : this.dataStructure.classname;
    }
  },
  async mounted() {
    await this.loadData();
    this.ready = true;
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.projectDataExplorer"),
          route: "/project/data-store"
        },
        {
          title: this.title,
          route: ""
        }
      ]);
    },
    openEditor() {
      if (this.dataStructure.owner_type.includes("DataPipeline")) {
        this.$router.push({
          name: "projectDataSetsEditor",
          params: { id: this.id }
        });
      } else {
        this.$router.push({
          name: "projectDataStructuresEditor",
          params: { id: this.id }
        });
      }
    },
    async loadData() {
      this.isBusy = true;
      this.entries = [];
      await this.loadDataStructure();
      this.setBreadcrumbs();
    },
    async loadDataStructure() {
      const dataStructures = new DataStructures();
      await dataStructures
        .get(this.id)
        .then(response => {
          this.dataStructure = response.data.data;
          this.dataStructure.fields = response.data.data.fields.filter(
            e =>
              e.show_in_view === 1 ||
              e.name === this.dataStructure.primary.replace("_", "")
          );
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.isBusy = false;
        });
    },
    async loadDataSet() {
      await QueryEditor.load(this.id)
        .then(response => {
          this.dataSet = response.data.data;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.isBusy = false;
        });
      await QueryEditor.getCollectionInfo(this.id)
        .then(response => {
          this.dataStructure = response.data.data;
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.isBusy = false;
        });
    },
    goBack() {
      this.$router.push({
        name: "projectDataExplorer"
      });
    }
  }
};
</script>
