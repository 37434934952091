<template>
  <div>
    <Details v-if="isDetails" />
    <Table v-else />
  </div>
</template>

<script>
import Details from "@/components/Projects/DataStore/Details";
import Table from "@/components/Projects/DataStore/Table";

export default {
  components: { Table, Details },
  props: {},
  data() {
    return {};
  },
  computed: {
    isDetails: function () {
      return ["projectDataExplorerDetails"].includes(this.$route.name);
    }
  },
  mounted() {},
  methods: {}
};
</script>
